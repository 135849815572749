<footer role="footer" class="ama-footer">
  <div class="ama-footer__container container">
    @defer {
      <a routerLink="/" class="ama-footer__ama-logo">
        <img class="ama-footer__ama-logo__img"
              ngSrc="assets/icons/ama-logo-reversed.svg"
              width="269"
              height="108"
              alt="American Medical Association">
        </a>
    } @placeholder (minimum 500ms) {
      <p>AMA Logo</p>
    }
  
    <div class="ama-footer__left">
      <p class="ama-footer__mission" [innerHTML]="mission"></p>
      <ul class="ama-footer__social-share">
        @for (share of socialShare; track share) {
          <li class="ama-footer__social-share__item">
            <a routerLink="share.url"
              class="ama-footer__social-share--icon ama-footer__social-share--{{ share.type }}"
              target="_blank"></a>
          </li>
        }
      </ul>
      <a href="{{ contact.url }}"
         class="ama-footer__connect ama-footer__link contact-mobile" [innerHTML]="contact.title"></a>

      <div class="ama-footer__connect">
        <p class="ama-footer__text ">Download AMA Connect app for &nbsp;</p>
        <ul class="ama-footer__apps">
          @for (app of appStoreLinks; track app) {
            <li class="ama-footer__apps__item">
              <a class="ama-footer__link ama-footer__link--external"
                href="{{ app.url }}" [innerHTML]="app.title"></a>
              <span class="ama-footer__apps__or">or</span>
            </li>
          }
        </ul>
      </div>
    </div>

    <div class="ama-footer__right">
      <div class="ama-footer__menu">
        <ul class="ama-footer__menu__list">
          @for (site of amaSites; track site) {
          <li class="ama-footer__menu__list__item">
            <a class="ama-footer__link"
               href="{{ site.url }}" [innerHTML]="site.text">
            </a>
          </li>
          }
        </ul>

        <ul class="ama-footer__menu__list">
          @for (site of amaAffliates; track site) {
          <li class="ama-footer__menu__list__item">
            <a class="ama-footer__link"
               href="{{ site.url }}" [innerHTML]="site.title">
            </a>
          </li>
          }
        </ul>
      </div>
      <div class="ama-footer__promo">
        <p>Join the AMA to get access to the member only residency calculator, residency guide, and dashboard where you can save, compare, and rank programs.</p>
        <div class="ama-footer__promo__cta">
          @if (!isAuthenticated){
          <ama-button class="ama-footer__promo__button freida-button"
                      text="Sign In"
                      buttonTheme="primary"
                      link="true"
                      (click)="login()">
          </ama-button>
          }
          @if (!isMember){
          <ama-button class="ama-footer__promo__button freida-button"
                      text="Become a Member"
                      buttonTheme="primary"
                      link="true"
                      url="/memberbenefits">
          </ama-button>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="ama-footer__menu-products">
    <ul class="ama-footer__menu-products__list">
      @for (partner of amaPartners; track partner) {
        <li class="ama-footer__menu-products__item">
          <a href="{{ partner.url }}"
            class="ama-footer__menu-products__link"
            [innerHTML]="partner.title">
          </a>
        </li>
      }
    </ul>

    <div class="ama-footer__bottom">
      <div class="ama-footer__bottom__container container">
        <ul class="ama-footer__utility">
          @for (link of legalLinks; track link) {
          <li class="ama-footer__utility__item">
            <a class="ama-footer__utility__link"
               href="{{ link.url }}"
               [innerHTML]=" link.title"
               >
            </a>
          </li>
          }
        </ul>
        <div class="ama-footer__copyright" [innerHTML]="copyright"></div>
      </div>
    </div>

  </div>
</footer>