<nav class="freida-navigation" role="navigation"
  *ngxObserve="currentUser$ as user;">
  <div class="freida-navigation__content">
    <div class="freida-navigation__content__mobile-menu"
    (click)="toggleMenu('trigger')"></div>

    <a class="freida-navigation__content__logo"
      (click)="navigateTo('/')">
        <img src="../../assets/freida-logo.svg"
          width="109"
          height="24" />
    </a>
    <!-- Dashboard Button -->
    @if (user?.roles == 'authenticated') {
      <div
        class="freida-navigation__content__button__container mobile">
        @if (!user?.isMember) {
          <ama-button
            [disabled]="false"
            class="freida-primary-solid"
            link="true"
            url="/memberbenefits"
            [text]="'Unlock Dashboard'"
            [buttonTheme]="'primary'">
          </ama-button>
        }
      </div>
    }

    @if (user?.isMember) {
      <div
        class="freida-navigation__content__button__container mobile">
        <ama-button class="freida-navigation__content__button ribbon-dashboard"
          (buttonClick)="goToDashboard(user)"
          text="Dashboard"
          buttonTheme="primary"
          [iconName]="'bookmark-outline'"
          reverse="true"
        data-test="dashboard-button"></ama-button>
      </div>
    }

    <div class="freida-navigation__content__menus" data-test="freida-navigation">
      <div class="freida-navigation__content__menu"
        data-test="freida-navigation-search-programs">
        <!-- Search Programs -->
        <a class="freida-navigation__content__menu__title"
          routerLinkActive="active"
          (click)="toggleMenu()">
          Search Programs
        </a>
      </div>
      <div class="freida-navigation__content__menu">
        <a class="freida-navigation__content__menu__title"
          (click)="navigateTo('/specialty')">
          Explore Specialties
        </a>
      </div>
      <div class="freida-navigation__content__menu">
          <a class="freida-navigation__content__menu__title"
            (click)="toggleToolsResourcesMenu()"
            routerLinkActive="active">
            Tools & Resources
            <ama-icon name="arrow-down"
            size=".75em"></ama-icon>
          </a>
          @if (showToolsResourcesMenu) {
            <div class="freida-navigation__content__menu submenu"
              >
              <a class="freida-navigation__content__menu__title tr-title"
                (click)="toggleToolsResourcesMenu()">
                Tools & Resources
                <ama-icon name="arrow-down"
                  size=".75em"></ama-icon>
              </a>
              <a class="freida-navigation__content__menu__title"
                (click)="navigateTo('/video-series'); toggleToolsResourcesMenu()"
                routerLinkActive="active">
                Video Gallery
              </a>
              <a class="freida-navigation__content__menu__title"
                (click)="roadToResidencyLink(user); toggleToolsResourcesMenu()"
                routerLinkActive="active">
                Residency Guide
              </a>
              <a class="freida-navigation__content__menu__title"
                (click)="navigateTo('/calculator'); toggleToolsResourcesMenu()"
                routerLinkActive="active">
                Residency Calculator
              </a>
              <a class="freida-navigation__content__menu__title"
                (click)="navigateTo('/residency-alignment?retake=true'); toggleToolsResourcesMenu()"
                routerLinkActive="active">
                OBGYN ACI
              </a>
              <a class="freida-navigation__content__menu__title"
                (click)="navigateTo('/contact'); toggleToolsResourcesMenu()"
                routerLinkActive="active">
                Contact FREIDA
              </a>
              <a class="freida-navigation__content__menu__title"
              (click)="navigateTo('/faq'); toggleToolsResourcesMenu()"
              routerLinkActive="active">
              FAQ
            </a>
            </div>
          }
      </div>
      <div class="freida-navigation__content__menu">
        <a class="freida-navigation__content__menu__title"
        (click)="goToProgramDirectorPortal(user)">
          Program Director Portal
        </a>
      </div>
    </div>
    <!-- Dashboard Button -->
    @if (user?.roles == 'authenticated') {
      <div
        class="freida-navigation__content__button__container desktop">
        @if (!user?.isMember) {
          <ama-button
            [disabled]="false"
            class="freida-primary-solid"
            link="true"
            url="/memberbenefits"
            [text]="'Unlock Dashboard'"
            [buttonTheme]="'primary'">
          </ama-button>
        }
      </div>
    }

    @if (user?.isMember) {
      <div
        class="freida-navigation__content__button__container desktop">
        <ama-button class="freida-navigation__content__button ribbon-dashboard"
          (buttonClick)="goToDashboard(user)"
          text="Dashboard"
          buttonTheme="primary"
          [iconName]="'bookmark-outline'"
          reverse="true"
        data-test="dashboard-button"></ama-button>
      </div>
    }
  </div>
  <div class="freida-navigation__search-drawer-anchor">
    <div class="freida-navigation__search-drawer"
      [@openClose]="isOpen ? 'open' : 'closed'">
      <div class="freida-navigation__search-drawer__container container"
        data-test="search-drawer">
        <div class="freida-navigation__search-drawer__search">
          <app-search-bar class="freida-app-search-bar"
          (submitSearchEvent)="toggleMenu()"></app-search-bar>
        </div>

        <div class="freida-navigation__search-drawer__related">
          <a class="freida-navigation__search-drawer__related__link"
            target=""
            title="Vacant Position List"
            [routerLink]="['vacant-position']">
            Vacant Position Listings
          </a>
          <a class="freida-navigation__search-drawer__related__link"
            title="Institution Directory"
            [routerLink]="['institution']">
            Institution Directory
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="mobile-navigation"
  [@mobileAnimation]="mobileIsOpen ? 'open' : 'closed'">
  <div class="mobile-navigation__actionbar">
    @if (mobileIcon === 'close') {
      <ama-icon size="1em"
        name="close"
        (click)="toggleMenu('trigger')"
      ></ama-icon>
    }
    @if (mobileIcon === 'arrow-right') {
      <ama-icon size="1em"
        name="arrow-right"
        (click)="homeMenu()"
      ></ama-icon>
    }
    <div class="mobile-navigation__actionbar__title">{{ mobileTitle }}</div>
  </div>
  @if (mobileTitle === '') {
    <div class="mobile-navigation__main"
      >
      <div class="mobile-navigation__item"
        [routerLink]="['/']"
      (click)="toggleMenu('trigger')">FREIDA Home</div>
      <div class="mobile-navigation__item"
        (click)="toggleMenu('Search Programs')">Search Programs <ama-icon name="right-chevron"
      size="0.46875rem"></ama-icon></div>
      @for (menu of menus; track menu; let i = $index) {
        <div class="mobile-navigation__item"
          (click)="makeMobilePage(menu.title, i, menu.url)">
          {{ menu.title }} @if (menu.children.length > 0) {
          <ama-icon
            name="right-chevron"
          size="0.46875rem"></ama-icon>
        }
      </div>
    }
  </div>
}
@if (mobileTitle !== '' && mobileTitle !== 'Search Programs') {
  <div class="mobile-navigation__secondary"
    >
    @for (menu of newChildren; track menu; let i = $index) {
      <div class="mobile-navigation__item"
        (click)="makeMobilePage(menu.title, i, menu.url)">
        {{ menu.title }} @if (menu.children) {
        <ama-icon
          name="right-chevron"
        size="0.46875rem"></ama-icon>
      }
    </div>
  }
</div>
}
@if (mobileTitle !== '' && mobileTitle === 'Search Programs') {
  <div class="mobile-navigation__search-programs"
    >
    <app-search-bar class="freida-app-search-bar-mobile"
    (submitSearchEvent)="typeaheadSubmit($event)"></app-search-bar>
    <div class="mobile-navigation__container">
      <div class="mobile-navigation__item"
        [routerLink]="['vacant-position']"
      (click)="toggleMenu('trigger')">Vacant Position Listing</div>
      <div class="mobile-navigation__item"
        [routerLink]="['institution']"
      (click)="toggleMenu('trigger')">Institution Directory</div>
    </div>
  </div>
}
</div>