import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { Component, Inject, isDevMode, OnDestroy, OnInit, PLATFORM_ID, APP_ID } from '@angular/core';
import { GoogleDataLayerService } from './services/google-data-layer.service';
import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { Observable, Subject, Subscription } from 'rxjs';
import { PromotionModel, UserModel } from './models';
import { RouterLinkWithHref, NavigationStart, Router, RouterOutlet, NavigationEnd } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { LazyLoadScriptService } from './services/lazy-load-script.service';
import { MENUS } from "./models/data/menus";
import { UserIdleService } from 'angular-user-idle';
import { BannerComponent } from './banner/banner.component';
import { RibbonComponent } from '../lib/components/ribbon/ribbon.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SystemBannerComponent } from './shared/system-banner/system-banner.component';
import { AmaModalComponent } from '../lib/components/modal/modal.component';
import { ButtonComponent } from '../lib/components/button/button.component';
import { AmaFooterComponent } from '../lib/components/footer/footer.component';
import { FooterComponent } from './footer/footer.component';
import { UserService, PromotionService } from './services';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CustomTextService } from './services/custom-text.service';
import { DashboardQueueService } from './services/dashboard-queue.service';
import { FieldsOptionsService } from './services/fields-options.service';
import { GatingService } from './services/gating.service';
import { InstitutionService } from './services/institution.service';
import { NavigationService } from './services/navigation.service';
import { SavedSearchService } from './services/saved-search.service';
import { SearchService } from './services/search.service';
import { SpecialtyService } from './services/specialty.service';
import { VacantPositionService } from './services/vacant-position.service';
import { AciListService } from './services/aci-list.service';
import { AciService } from './services/aci.service';
import { Select } from '@ngxs/store';
import { UserActions } from './store/user/actions/user.actions';
import { UserState, UserStateModel } from './store/user/state/user.state';
import { DashboardService } from './services/dashboard.service';
import { DashboardApiService } from './store/services/dashboard-api.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingResultsComponent } from './shared/template/loading-results/loading-results.component';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet,
    RouterLinkWithHref,
    RouterOutlet,
    BannerComponent,
    RibbonComponent,
    NavigationComponent,
    SystemBannerComponent,
    AmaModalComponent,
    ButtonComponent,
    FooterComponent,
    AmaFooterComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    AngularSvgIconModule,
    LoadingResultsComponent
  ],
  providers: [
    CookieService,
    UserService,
    LazyLoadScriptService,
    UserIdleService,
    AuthService,
    GoogleDataLayerService,
    PromotionService,
    CustomTextService,
    DashboardQueueService,
    DashboardService,
    FieldsOptionsService,
    GatingService,
    InstitutionService,
    NavigationService,
    SavedSearchService,
    SearchService,
    SpecialtyService,
    VacantPositionService,
    AciListService,
    AciService,
    DashboardApiService,
    provideNgxMask()
  ]
})

export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private getCurrentUserSubscription: Subscription;
  public getMenuItemsSubscription: Subscription;
  private readonly siteMenus: string[] = ['product', 'main', 'global', 'footer'];
  public bannerPromotions: PromotionModel[] = [];
  public systemWideMessage: PromotionModel[] = [];
  public amaNavigationMenuItems = [];
  public freidaMenus: any;
  public title = 'Freida';
  public offline: boolean;
  public promptEvent: any;
  public deferredPrompt: any;
  public showIdleModal = false;
  public userMenuGroup: any;
  public bannerIsActivated: boolean = false;
  public jwtCookieValue: any;
  public closePromoBanner: boolean = false;

  public getUserMenuGroup() {
    if (isPlatformBrowser(this.platformId)) {
      return [
        {
          text: this.authService.getAuthToken() === '' ? 'Sign In' : 'Sign Out',
          link:
            this.authService.getAuthToken() === ''
              ? this.authService.getSamlSignInUrl(window.location.href)
              : this.authService.getSamlSignOutUrl(window.location.href, window.location.hostname)
        },
        {
          text: 'Create free account',
          link: 'https://fsso.ama-assn.org/sign-up'
        },
        {
          text: 'Join / Renew',
          link: 'https://member.ama-assn.org/join-renew/'
        },
        {
          text: 'Member Benefits',
          link: 'https://freida.ama-assn.org/memberbenefits'
        },
        {
          text: 'AMA Account',
          link: 'https://amc.ama-assn.org/contact-preferences'
        },
        {
          text: 'Email Preferences',
          link: ' https://amc.ama-assn.org/contact-preferences'
        }
      ];
    }

    return [];
  }

  public user = [this.userService.defaultUser];
  public joinButtons = [
    {
      text: 'Join',
      link: 'https://member.ama-assn.org/join-renew/'
    },
    {
      text: 'Renew',
      link: 'https://member.ama-assn.org/join-renew/'
    }
  ];
  @Select(UserState)
  public user$: Observable<UserStateModel>;
  public currentUser$: Observable<UserModel>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private googleDataLayerService: GoogleDataLayerService,
    private userService: UserService,
    private authService: AuthService,
    private promotionService: PromotionService,
    private router: Router,
    private lazyLoadService: LazyLoadScriptService,
    private userIdle: UserIdleService,
    private cookieService: CookieService,
    @Inject(APP_ID) private appId: string,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    console.warn(isDevMode());
    this.userMenuGroup = this.getUserMenuGroup();

    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      this.googleDataLayerService.trackAnalytics({
        event: 'page', pageName: event.urlAfterRedirects
      });
    });

    // Auto Sign in Function
    if (isPlatformBrowser(this.platformId) && window) {
      const isSignedIn = sessionStorage.getItem('isSignedIn');
      const pdHSessionId = 'PD-H-SESSION-ID';
      const freidaJWT = 'FreidaJwt';
      const pattern = '^1';

      if (!isSignedIn) {
        if (this.cookieService.check(pdHSessionId) && this.cookieService.get(pdHSessionId).length > 0 && this.cookieService.get(pdHSessionId).match(pattern)) {
          this.authService.login();
          sessionStorage.setItem('isSignedIn', 'true');

          if (this.cookieService.check(freidaJWT)) {
            this.jwtCookieValue = jwtDecode(this.cookieService.get('FreidaJwt'))

            const jwtNow = dayjs().format();
            const jwtExpire = dayjs(this.jwtCookieValue.exp).format();

            if (jwtNow > jwtExpire) {
              this.authService.refreshToken().subscribe();
            }
          }
        }
      } else {
        sessionStorage.removeItem('isSignedIn');
      }
    }
  }


  @Dispatch() public getUser = () => new UserActions.UpdateUser();

  public stayLoggedIn() {
    this.userIdle.resetTimer();
    this.showIdleModal = false;
  }

  public ngOnInit() {

    if (isPlatformBrowser(this.platformId) && window) {
      if (sessionStorage.getItem('bannerAcknowlegement') === 'true') {
        this.bannerIsActivated = true
      }
    }

    // DISPATCH UPDATE USER
    this.getUser();

    this.setUpHashRedirects();
    // get User Selector from Store, set user var if user is available.
    this.getCurrentUserSubscription = this.user$
      .subscribe((user) => {
        const currentUser = user.user;
        if (currentUser) {
          this.user = [currentUser];
          if (currentUser.uuid) {
            this.userIdle.startWatching();
            this.userIdle.ping$.subscribe(() => {
              this.authService.refreshToken().subscribe();
            });

            // Start watching when user idle is starting.
            this.userIdle.onTimerStart().subscribe(() => {
              this.showIdleModal = true;
            });

            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe(() => this.signOut());
          }
        }
        else {
          // Add action to save default User to state
          this.user = [this.userService.defaultUser];
        }

        if (currentUser.tam_id) {
          this.googleDataLayerService.setUserAnalyticsData(currentUser);
        }
      });

    this.freidaMenus = MENUS;

    this.amaNavigationMenuItems = MENUS.global;


    this.subscriptions.push(
      this.promotionService.getAnnoucementBanner()
        .pipe(takeUntil(this.destroy$))
        .subscribe((promos) => {
          this.bannerPromotions = promos;
        })
    );

    this.subscriptions.push(
      this.promotionService.getSystemWideMessage()
        .pipe(takeUntil(this.destroy$))
        .subscribe((promos) => {
          this.systemWideMessage = promos;
        })
    );

    // Load External scripts
    this.lazyLoadService.loadScript('/assets/scripts/google-analytics.js');

    // Load New Relic only in PROD
    if (window.location.href.indexOf("freida") > -1) {
      this.lazyLoadService.loadScript('/assets/scripts/newrelic.js');
    }
  }


  public learnMoreClicked(bannerUrl) {
    if (isPlatformBrowser(this.platformId) && window) {
      window.open(bannerUrl[0].ctaURL, '_blank');
    }
  }

  public closeSystemBanner() {
    if (isPlatformBrowser(this.platformId) && window) {
      this.bannerIsActivated = true;
      sessionStorage.setItem('bannerAcknowlegement', 'true');
    }
  }

  private setUpHashRedirects() {
    const redirectPattern = /^\/(Freida)?#/i;

    const routerNavigationSubscription =
      this.router.events.pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      ).subscribe((event: NavigationStart) => {
        if (!!event.url && event.url.match(redirectPattern)) {
          this.router.navigateByUrl(event.url.replace(redirectPattern, ''));
        }
      });

    this.subscriptions.push(routerNavigationSubscription);
  }

  public signIn() {
    this.authService.login();
  }

  public signOut() {
    if (this.showIdleModal) {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
      this.showIdleModal = false;
    }
    this.authService.logout(true);
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.getCurrentUserSubscription) {
      this.getCurrentUserSubscription.unsubscribe();
    }

    if (this.getMenuItemsSubscription) {
      this.getMenuItemsSubscription.unsubscribe();
    }

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
