<div class="ama-ribbon">
  <div class="ama-ribbon__content">
    <nav class="ama-ribbon__content__container"
          role="ribbon"
          (click)="toggleMenu()"
          (amaClickOutside)="closeMenu()">

      <!-- Mobile hamburger menu -->
      <div class="ama__hamburger-menu"
            [ngClass]="isOpen ? 'active' : ''">
        <div class="ama__hamburger-menu__center"></div>
      </div>

      <button class="ama-ribbon__button">
        <p class=" ama-ribbon__button__text">{{ buttonText }}</p>
        <i class="ama-ribbon__button__caret"></i>
      </button>

      <div class="ama-ribbon__menu"
            [@openClose]="isOpen ? 'open' : 'closed'">
        <ul class="ama-ribbon__menu__list">
          <li class="ama-ribbon__menu__item"
              *ngFor="let item of globalMenuItems">
            <a href="{{ item.url }}"
                title="{{ item.title }}"
                target="_self"
                class="ama-ribbon__menu__link">
              <span>{{ item.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <a href="https://www.ama-assn.org/"
        class="ama-ribbon__ama-logo">
      <img src="assets/icons/ama-logo-reversed.svg"
            alt="American Medical Association"
            width="110"
            height="45"
            class="ama-ribbon__ama-logo--screen">
      <img src="assets/icons/ama-logo.svg"
            width="110"
            height="45"
            alt="American Medical Association"
            class="ama-ribbon__ama-logo--print">
    </a>

    <a (click)="navigateTo('/memberbenefits')"
        class="ama-ribbon__benefits">
      Member Benefits
    </a>

    <div class="ama-ribbon__join">
      <a href="{{ button.link }}"
          type="button"
          class="ama-ribbon__join__button"
          *ngFor="let button of joinButtons">
        {{ button.text }}
      </a>
    </div>

    <div class="ama-ribbon__sign-in-dropdown"
          (click)="toggleUserMenu()"
          (amaClickOutside)="closeUserMenu()">
      <img src="assets/icons/ama-user.svg"
            class="ama-ribbon__sign-in-dropdown__user-icon"
            width="24"
            height="25"
            alt="American Medical Association">
      <span *ngFor="let item of user"
            class="ama-ribbon__sign-in-dropdown__text">{{ item.name }}</span>
      <i class="ama-ribbon__sign-in-dropdown__caret"></i>
      <div class="ama-ribbon__sign-in-dropdown__menu"
            [@openClose]="isUserOpen ? 'openUser' : 'closeUser'">
        <ul class="ama-ribbon__sign-in-dropdown__menu__group">
          <li *ngFor="let item of userMenuGroup"
              [ngClass]="{'hidden': item.text === 'Create free account' && hideCreateAccountLink}">
            <a title="{{ item.text }}"
                target="_self"
                (click)="checkForLogout(item)"
                class="ama-ribbon__sign-in-dropdown__menu__link">
              <span>{{ item.text }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>